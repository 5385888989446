import * as React from 'react';

import * as GqlResult from 'util/graphql/GqlResult';
import gqlNoticeFeed from 'util/gqlNoticeFeed';
import { StaticNotice } from '../models/staticContent/StaticNotice';
import { FeedSemanticKey } from '../models/staticContent/Feed';
import { CollectionList, CollectionItem } from './Collection/Collection';
import PlainNotice from './ui/Notice/PlainNotice';
import { Throw } from './error/Throw';
import Notice from './Notice';
import ReadMoreAndCloseButtons from './ReadMoreAndCloseButtons';
import { ColumnSectionFooter, ColumnSectionFooterLeft, ColumnSectionFooterRight } from './ColumnSection/ColumnSection';

export interface StaticNoticeEdge {
  cursor: string;
  node: StaticNotice;
}

interface ExpansionState {
  expanded: boolean;
  displayCount: number;
}

export interface NoticeFeedProps {
  // The semantic key of the feed.
  feed: FeedSemanticKey;
  // The tags the feed should be filtered against. Useful for reusing feeds and filtering against specific domains
  tagPaths?: Array<Array<string>>;
  // The semantic category the feed should be filtered against.
  contentCategorySemanticKeys?: Array<string>;
  // How many elements to load from GraphQL.
  initialLoadCount: number;
  // How many elements to display in truncated mode.
  truncatedDisplayCount?: number;
  // How many more elements to load when expanded.
  displayMoreInterval: number;
  // Show the first element with a special "latest" styling
  showFirstAsLatestStyle: boolean;
  // Display images
  displayImage: boolean;
  // Display content category
  displayCategory: boolean;
  // Display publishedAt date
  displayPublishedAt: boolean;
  displayDescription?: boolean;
  hasSmallTitle?: boolean;
  // Truncation callback
  onTruncate?: () => void;
  readMoreLink?: React.ReactElement;
  actionsHasTopPadding?: boolean;
  emptyFeedMessage?: string;
  linkArrowIconColor?: string;
  fontSize?: string;
  redFooterIconsOnHover?: boolean;
  hideReadMoreLinkUntilFirstNotice?: boolean;
}

export const NoticeFeed: React.FC<NoticeFeedProps> = ({
  feed,
  tagPaths,
  contentCategorySemanticKeys,
  initialLoadCount,
  truncatedDisplayCount,
  displayMoreInterval,
  showFirstAsLatestStyle,
  displayCategory,
  displayImage,
  displayPublishedAt,
  displayDescription = true,
  hasSmallTitle,
  onTruncate,
  readMoreLink = null,
  emptyFeedMessage,
  linkArrowIconColor,
  fontSize,
  redFooterIconsOnHover,
  hideReadMoreLinkUntilFirstNotice,
}) => {
  const nonExpandedDisplayCount = truncatedDisplayCount === undefined ? initialLoadCount : truncatedDisplayCount;
  const isMarketingArticle = feed === 'forside-reklame';

  const [expansionState, setExpansionState] = React.useState<ExpansionState>({
    expanded: false,
    displayCount: nonExpandedDisplayCount,
  });

  const displayedCount = expansionState.expanded ? expansionState.displayCount : nonExpandedDisplayCount;

  const handleTruncate = () => {
    setExpansionState({
      expanded: false,
      displayCount: nonExpandedDisplayCount,
    });
    if (onTruncate) onTruncate();
  };

  const { fetchFirstPage, fetchNextPage } = gqlNoticeFeed(
    feed,
    initialLoadCount,
    displayMoreInterval,
    tagPaths,
    contentCategorySemanticKeys
  );

  return fetchFirstPage
    .map((publishedNotices): React.ReactElement | null => {
      const noticesInCache = publishedNotices.edges.length;
      const canDisplayMore = displayedCount < noticesInCache || publishedNotices.pageInfo.hasNextPage;

      const displayMore = () => {
        const desiredDisplayedCount = displayedCount + displayMoreInterval;

        if (publishedNotices.pageInfo.hasNextPage) {
          fetchNextPage(publishedNotices.pageInfo.endCursor);
        }

        setExpansionState({
          expanded: true,
          displayCount: desiredDisplayedCount,
        });
      };

      if (publishedNotices.edges.length === 0 && !!emptyFeedMessage) {
        return <PlainNotice noPaddingSpace content={emptyFeedMessage} />;
      }

      return (
        <div>
          <CollectionList>
            {publishedNotices.edges.slice(0, displayedCount).map((edge, i) => (
              <CollectionItem key={edge.cursor} hasSeparator={!isMarketingArticle} padding={hasSmallTitle ? 'small' : 'large'}>
                <Notice
                  feed={feed}
                  notice={edge.node}
                  isLatest={showFirstAsLatestStyle && i === 0}
                  displayCategory={displayCategory}
                  displayImage={displayImage}
                  displayPublishedAt={displayPublishedAt}
                  displayDescription={displayDescription}
                  hasSmallTitle={hasSmallTitle}
                  linkArrowIconColor={linkArrowIconColor}
                  fontSize={fontSize}
                />
              </CollectionItem>
            ))}
          </CollectionList>
          {!isMarketingArticle && (
            <ColumnSectionFooter>
              {!readMoreLink && (
                <ColumnSectionFooterLeft>
                  <ReadMoreAndCloseButtons
                    onLoadMore={displayMore}
                    onClose={handleTruncate}
                    displayLoadMoreAction={canDisplayMore}
                    displayCloseAction={displayedCount > nonExpandedDisplayCount}
                    redIconsOnHover={redFooterIconsOnHover}
                  />
                </ColumnSectionFooterLeft>
              )}
              {readMoreLink && hideReadMoreLinkUntilFirstNotice ? (
                publishedNotices.edges.length === 0 ? null : (
                  <ColumnSectionFooterRight>{readMoreLink}</ColumnSectionFooterRight>
                )
              ) : (
                <ColumnSectionFooterRight>{readMoreLink}</ColumnSectionFooterRight>
              )}
            </ColumnSectionFooter>
          )}
        </div>
      );
    })
    .mapLoading((): React.ReactElement | null => null)
    .getOrElseGet((notOk) => <Throw error={GqlResult.notOkToJuridikaError(notOk)} />);
};
