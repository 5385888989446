export interface GqlConnection<EdgeT> {
  pageInfo: {
    hasNextPage: boolean;
    endCursor: string;
    totalSearchResults?: number;
  };
  edges: Array<EdgeT>;
}

// Utility for concatenating two graphql connections, typically used in a "fetchMore" scenario.
// Updates pageInfo with the pageInfo of the latter argument.
// Retains eventual "hidden" graphql properties like __typename.
export const concatGqlConnections = <EdgeT>(head: GqlConnection<EdgeT>, tail: GqlConnection<EdgeT>): GqlConnection<EdgeT> => ({
  ...head,
  ...tail,
  pageInfo: tail.pageInfo,
  edges: [...head.edges, ...tail.edges],
});
