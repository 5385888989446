import React from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import createFontStyles from 'util/createFontStyles';
import Image from 'components/Image';
import ReadMoreLink from 'components/ReadMoreLink';
import { portraitPlaceholder } from 'util/literatureUtils';
import nimLogo from '../../../images/nim_logo.png';

const SerialHeaderWrapperCss = styled.div`
  display: flex;
  padding: 1.25rem 1.25rem 1.1rem;
  background-image: url('https://res.cloudinary.com/dylsvi3ok/image/upload/v1630932703/Backgrounds/dotty_gxllis.png');
  border-top: 4px solid ${({ theme }) => theme.colorTokens.grey[900]};
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[900]};
  align-items: flex-end;

  ${createMediaQuery(
    'xxxs',
    css`
      flex-direction: column;
      align-items: center;
    `
  )}

  ${createMediaQuery(
    'xs',
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
    `
  )}

  ${createMediaQuery(
    'sm',
    css`
      justify-content: space-between;
    `
  )}

  ${createMediaQuery(
    'md',
    css`
      flex-direction: column;
      align-items: center;
    `
  )}

  ${createMediaQuery(
    'lg',
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
    `
  )}

  svg {
    ${createMediaQuery(
      'md',
      css`
        max-width: 100%;
        max-height: 10rem;
      `
    )}
  }
`;

const FigureCss = styled.figure`
  text-align: center;

  ${createMediaQuery(
    'xs',
    css`
      text-align: left;
    `
  )}

  img {
    max-width: 10rem;

    ${createMediaQuery(
      'xs',
      css`
        max-width: 100%;
      `
    )}
  }
`;

const ContentCss = styled.div`
  max-width: 30rem;

  ${createMediaQuery(
    'sm',
    css`
      max-width: 35rem;
    `
  )}

  ${createMediaQuery(
    'md',
    css`
      max-width: 20rem;
    `
  )}
`;

const TitleCss = styled.h3`
  margin: 0 0 0.4rem;

  ${(props) =>
    createFontStyles({
      family: props.theme.fontFamilies.primary,
      size: 2.2,
    })}

  ${createMediaQuery(
    'lg',
    css`
      font-size: 2rem;
    `
  )}

  ${createMediaQuery(
    'mlg',
    css`
      font-size: 2.4rem;
    `
  )}
`;

const SubTitleCss = styled.span`
  ${(props) =>
    createFontStyles({
      family: props.theme.fontFamilies.primary,
      size: 1.25,
    })}
`;

const FooterCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${createMediaQuery(
    'xs',
    css`
      flex-direction: row;
      align-items: center;
    `
  )}

  ${createMediaQuery(
    'md',
    css`
      flex-direction: column;
      align-items: flex-start;
    `
  )}

  ${createMediaQuery(
    1500,
    css`
      flex-direction: row;
      align-items: center;
    `
  )}
  img {
    margin-left: 0;
    max-width: 15rem;

    ${createMediaQuery(
      'xs',
      css`
        margin-left: 1.5rem;
      `
    )}
  }
`;

export interface SerialHeaderProps {
  contributors: Array<{
    id: string;
    names: string[];
    hasPortrait: boolean;
    slug?: string;
    customPortrait?: string;
  }>;
  slug?: string;
  title: string;
  description: string;
  customImage?: React.ReactElement;
  byline?: string;
  className?: string;
}

const SerialHeader: React.FC<SerialHeaderProps> = ({
  contributors,
  slug,
  title,
  description,
  customImage,
  byline,
  className = '',
}) => {
  return (
    <SerialHeaderWrapperCss className={className}>
      {contributors.length > 0 ? (
        <FigureCss className={slug}>
          <Image src={contributors[0].customPortrait || portraitPlaceholder(200)} />
        </FigureCss>
      ) : null}
      {customImage}

      <ContentCss>
        <TitleCss>{title}</TitleCss>
        {contributors.length > 0 ? <SubTitleCss>{byline || `av ${contributors[0].names.join(' ')} `}</SubTitleCss> : null}
        <p>{description}</p>

        {slug && slug === 'rett-skal-være-rett' && (
          <FooterCss>
            <p>I samarbeid med </p> <img alt="NIM logo" src={nimLogo} />
          </FooterCss>
        )}
        {slug && slug !== 'rett-skal-være-rett' && <ReadMoreLink to={`/innsikt/serier/${slug}`}>{title}</ReadMoreLink>}
      </ContentCss>
    </SerialHeaderWrapperCss>
  );
};

export default SerialHeader;

export const SerialHeaderCss = styled(SerialHeader)`
  align-items: center;

  img {
    max-width: 230px;
    min-width: 100px;
    width: 100%;
  }

  ${createMediaQuery(
    'lg',
    css`
      figure {
        align-self: flex-end;
      }
    `
  )}
`;
