import * as React from 'react';
import { SerialHeaderCss } from './SerialHeader';

const DrommekraftSerialHeader: React.FC = () => (
  <SerialHeaderCss
    title="Dømmekraft og drømmekraft"
    description="En juridisk biografi i ni deler, blant annet om de store omveltningene i Høyesterett på 1990-tallet, den moderne sameretten og innføringen av europeiske menneskerettigheter."
    contributors={[
      {
        id: 'dommekraft-og-drommekraft',
        names: ['Carsten Smith'],
        hasPortrait: false,
        customPortrait:
          'https://res.cloudinary.com/dylsvi3ok/image/upload/v1653475673/portraits/Smith_Carsten_cropped_yv6tll.png',
      },
    ]}
  />
);

export default DrommekraftSerialHeader;
