import React from 'react';
import * as hast from '@universitetsforlaget/hast';
import { Link } from 'react-router-dom';

import { HastFragment, reactChildren } from '../components/Hast';
import { staticContentImageUrlToCloudfrontUrl } from './urlHelpers';

interface RenderOverride {
  tagName: string;
  match?: (node: hast.HastElementNode) => boolean;
  render: (node: hast.HastElementNode, config: HastConfig) => React.ReactElement | null;
}

/**
 * Override img by setting a bunch of extra properties
 */
export const overrideImg = (props: { alt?: string; role?: string; loading?: 'lazy' }): RenderOverride => ({
  tagName: 'img',
  render: (node) => {
    const imageSrc = node.properties?.src;
    return React.createElement('img', {
      ...hast.hastPropertiesToJs(hast.htmlSpace, node.properties),
      ...props,
      ...(imageSrc && { src: staticContentImageUrlToCloudfrontUrl(imageSrc) }),
    });
  },
});

/**
 * Override iframe rendering by setting a title
 */
export const overrideIframeTitle = (title: string): RenderOverride => ({
  tagName: 'iframe',
  render: (node, config) =>
    React.createElement(
      'iframe',
      hast.hastPropertiesToJs(hast.htmlSpace, {
        ...node.properties,
        title,
      }),
      reactChildren(node.children, config)
    ),
});

/**
 * Overrides that are _always_ present
 */
const STATIC_OVERRIDES: RenderOverride[] = [
  {
    tagName: 'script',
    // Disable XSS ;)
    render: () => null,
  },
  {
    tagName: 'fragment',
    render: (node, config) =>
      React.createElement(HastFragment, {
        nodes: node.children,
        config,
      }),
  },
  {
    tagName: 'a',
    render: (node, config) => {
      const { href = '/', ...rest } = node.properties || {};

      // Only relative/internal URLs should render with Link
      if (href.startsWith('/')) {
        return React.createElement(
          Link,
          {
            ...hast.hastPropertiesToJs(hast.htmlSpace, rest),
            to: href,
          },
          reactChildren(node.children, config)
        );
      }

      return React.createElement(
        'a',
        hast.hastPropertiesToJs(hast.htmlSpace, node.properties || {}),
        reactChildren(node.children, config)
      );
    },
  },
];

export class HastConfig {
  readonly customOverrides: RenderOverride[];

  readonly overridesByTagName: Record<string, RenderOverride[]>;

  constructor(customOverrides: RenderOverride[]) {
    this.customOverrides = customOverrides;

    const overridesByTagName: Record<string, RenderOverride[]> = {};

    // Index all overrides in by tagName, for quick lookup:
    customOverrides.concat(STATIC_OVERRIDES).forEach((override) => {
      const overrides = overridesByTagName[override.tagName];
      if (overrides) {
        overrides.push(override);
      } else {
        overridesByTagName[override.tagName] = [override];
      }
    });

    this.overridesByTagName = overridesByTagName;
  }
}

export const DEFAULT_HAST_CONFIG = new HastConfig([]);
