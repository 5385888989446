import * as React from 'react';
import styled from 'styled-components';

import { FONT_FAMILIES } from 'theme/config/defaultTheme';
import { ColorTokenValues } from 'theme/config/types';
import createFontStyles from 'util/createFontStyles';
import SectionContainer from '../../SectionContainer';
import WidthConstraints from '../../WidthConstraints';

export interface PlainNoticeProps {
  backgroundColor?: ColorTokenValues;
  textColor?: ColorTokenValues;
  content?: string;
  textAlign?: 'auto' | 'left' | 'right' | 'center';
  noPaddingSpace?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const PlainNoticeLayoutCss = styled.div<{ noPaddingSpace?: boolean }>`
  padding: ${(props) => (props.noPaddingSpace ? 0 : props.theme.spacing.md)};
`;

const PlainNoticeContentCss = styled.p<{
  textColor?: ColorTokenValues;
  textAlign?: 'auto' | 'left' | 'right' | 'center';
}>`
  color: ${(props) => (props.textColor ? props.textColor : props.theme.colorTokens.grey[900])};
  text-align: ${(props) => (props.textAlign && props.textAlign) || 'left'};

  ${createFontStyles({
    family: `${FONT_FAMILIES.secondary}`,
    size: 1.125,
    weight: 400,
    lineHeight: 1.5,
  })};
`;

const PlainNotice: React.FC<PlainNoticeProps> = ({
  backgroundColor,
  textColor,
  content,
  textAlign,
  noPaddingSpace,
  children,
}) => {
  return (
    <>
      <SectionContainer backgroundColor={backgroundColor}>
        <WidthConstraints fullWidth>
          <PlainNoticeLayoutCss noPaddingSpace={noPaddingSpace}>
            {!!content && (
              <PlainNoticeContentCss textColor={textColor} textAlign={textAlign}>
                {content}
              </PlainNoticeContentCss>
            )}
            {children}
          </PlainNoticeLayoutCss>
        </WidthConstraints>
      </SectionContainer>
    </>
  );
};

export default PlainNotice;
