import * as React from 'react';
import ArrowRight from 'icons/ArrowRight';
import styled from 'styled-components';
import { OldLinkButton } from './OldButton';

interface ReadMoreLinkProps {
  to: string;
  children: React.ReactNode | React.ReactNode[];
}

const ReadMoreLinkCss = styled(OldLinkButton)`
  padding: 3px 0;
  height: 1.5625rem;

  .c-btn__leftIcon,
  .c-btn__rightIcon {
    svg {
      width: 0.75rem;
      height: 0.75rem;

      * {
        fill: ${({ theme }) => theme.colorTokens.grey[900]};
      }
    }
  }
`;

const ReadMoreLink: React.FC<ReadMoreLinkProps> = ({ to, children }) => (
  <ReadMoreLinkCss to={to} iconRight={ArrowRight}>
    {children}
  </ReadMoreLinkCss>
);

export default ReadMoreLink;
