import * as React from 'react';
import { SerialHeaderCss } from './SerialHeader';

export const JussensHelterSerialHeader: React.FC = () => {
  const contributor = {
    hasPortrait: true,
    id: '',
    names: ['Hans Petter', 'Graver'],
    customPortrait: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1653475673/portraits/portrait-500px_1_xuquy9.png',
  };

  return (
    <SerialHeaderCss
      contributors={[contributor]}
      title="Jussens helter"
      slug="jussens-helter"
      description="En serie i tretten deler om jurister som valgte å følge sin samvittighet fremfor å leve opp til systemets krav."
    />
  );
};
