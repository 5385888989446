import * as React from 'react';
import styled from 'styled-components';
import { SerialHeaderCss } from './SerialHeader';

const DommerpostLuxemburgSerialHeaderCss = styled(SerialHeaderCss)`
  h3 {
    ${({ theme }) => `color: ${theme.colorTokens.red[800]};`}
    font-weight: 500;
  }

  span {
    font-style: italic;
    ${({ theme }) => `font-family: ${theme.fontFamilies.secondary};`}
  }
`;

const DommerpostLuxemburgSerialHeader: React.FC = () => {
  return (
    <DommerpostLuxemburgSerialHeaderCss
      contributors={[
        {
          hasPortrait: true,
          id: '',
          names: ['Per', 'Christiansen'],
          slug: 'per-christiansen',
          customPortrait: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1653475673/portraits/Per-Christiansen_oxqlmf.png',
        },
      ]}
      byline="med EFTA-dommer Per Christiansen"
      title="Dommerpost Luxemburg"
      description="EFTA-dommer Per Christiansen har jobbet med EU/EØS-retten i en årrekke. I denne serien deler han sine vurderinger av utviklingen i EU, med vekt på juridiske spørsmål og EU-domstolens rolle."
    />
  );
};

export default DommerpostLuxemburgSerialHeader;
