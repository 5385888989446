import React from 'react';
import * as hast from '@universitetsforlaget/hast';
import styled, { css } from 'styled-components';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { formatDate } from 'util/dateHelpers';
import { staticPageRelativeUrl, isExpertComment, noticeHeadersSelector } from 'util/staticContentHelpers';
import { getPortraitUrl } from 'util/literatureUtils';
import { HastSelector, takeFirst, matchAllOf, matchTagName, matchPosition } from 'util/HastSelector';
import { HastConfig, overrideIframeTitle, overrideImg } from 'util/hastConfig';
import { renderFragmentElements } from 'util/HastUtils';
import ArrowRight from 'icons/ArrowRight';
import DividerDot from 'icons/DividerDot';
import createMediaQuery from 'util/createMediaQuery';
import { StaticNotice, StaticNoticeContributor } from '../models/staticContent/StaticNotice';
import PromotedContentLink from './PromotedContent/PromotedContentLink';
import PromotedContentHeading from './PromotedContent/PromotedContentHeading';
import MediaCard from './MediaCard';
import { Hast, HastFragment } from './Hast';

const NoticeWrapperCss = styled.div<{ shouldFlex: boolean; hasSeparator: boolean; isMarketingArticle?: boolean }>`
  ${(props) =>
    props.shouldFlex &&
    css`
      ${createMediaQuery(
        'sm',
        css`
          display: flex;
        `
      )}
    `}

  ${(props) =>
    props.hasSeparator &&
    !props.isMarketingArticle &&
    css`
      padding-bottom: 1.25rem;
      border-bottom: 0.0625rem solid ${props.theme.colorTokens.brown[400]};
    `}

    ${({ isMarketingArticle }) =>
    isMarketingArticle &&
    css`
      align-items: center;
    `}
`;

const ContentCss = styled.div<{ fontSize?: string; spaceAround?: boolean; isMarketingArticle?: boolean }>`
  word-break: break-word;
  font-size: ${(props) => props.fontSize ?? '1.25rem'};

  ${({ spaceAround }) =>
    spaceAround &&
    `
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 3.25rem;
  `}

  .icon-dividerDot {
    margin: 0 0.625rem;
    vertical-align: middle;
  }

  ul {
    padding-left: 1.125rem;
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  ${({ isMarketingArticle }) =>
    isMarketingArticle &&
    css`
      align-self: stretch;
      width: 100%;
    `}
`;

const PublishedAtCss = styled.span`
  font-size: 1rem;
  text-transform: uppercase;
`;

const TypeCss = styled.div`
  color: ${(props) => props.theme.colorTokens.purple[500]};
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
`;

const DescriptionCss = styled.div<{ isMarketingArticle?: boolean }>`
  display: inline;

  p:first-child {
    display: inline;

    ${({ isMarketingArticle }) =>
      isMarketingArticle &&
      css`
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      `}
  }
`;

const MediaCardWrapperCss = styled.div<{ shouldFlex: boolean; isMarketingArticle?: boolean }>`
  ${(props) =>
    props.shouldFlex &&
    css`
      margin-right: 1.875rem;
    `}

  flex: 0 0 30%;

  .c-mediaCard__img img {
    width: initial;
    max-width: 100%;
  }

  .c-mediaCard__title {
    margin: 0;
  }

  .c-mediaCard__title a {
    font-family: ${(props) => props.theme.fontFamilies.primary};
    font-size: 0.875rem;
  }

  ${({ isMarketingArticle }) => isMarketingArticle && css``}

  display: flex;
  align-items: center;
  margin-right: 0;
  margin-bottom: 15px;

  > div {
    display: flex;
    align-items: center;
    > a {
      display: flex;
      align-items: center;
    }
  }

  ${createMediaQuery(
    'sm',
    css`
      margin-bottom: 0;
      margin-right: 1.875rem;
    `
  )}
`;

const noticeLinkHref = (notice: StaticNotice): string | null => {
  if (notice.link && notice.link.slug) {
    return staticPageRelativeUrl(notice.link.slug).toString();
  }

  return notice.customLink;
};

const mainImageSelector = new HastSelector({
  mainImage: takeFirst(1, matchAllOf(matchTagName('img'), matchPosition(0))),
});

const MainImage: React.FC<{
  hastConfig: HastConfig;
  linkHref: string | null;
  isLatest: boolean;
  expertContributor: StaticNoticeContributor | null;
  allHeaders: hast.HastFragmentNode;
  title: string;
  shouldFlex: boolean;
  isMarketingArticle?: boolean;
}> = ({ hastConfig, linkHref, isLatest, expertContributor, allHeaders, title, shouldFlex, isMarketingArticle }) => {
  const juridikaConfig = useJuridikaConfig();

  if (expertContributor) {
    return (
      <MediaCardWrapperCss shouldFlex={shouldFlex} isMarketingArticle={isMarketingArticle || false}>
        <MediaCard
          media={{
            ariaLabel: title,
            path: linkHref || '',
            imgUrl: getPortraitUrl(juridikaConfig, expertContributor, isLatest ? 500 : 200),
            title: expertContributor.names.join(' '),
            subtitle: '',
          }}
        />
      </MediaCardWrapperCss>
    );
  }
  const mainImageFragment = mainImageSelector.select('mainImage', allHeaders);
  if (!mainImageFragment.children || mainImageFragment.children.length === 0) return null;

  return (
    <MediaCardWrapperCss shouldFlex={shouldFlex}>
      <MediaCard
        media={{
          ariaLabel: title,
          path: linkHref || '',
          imgUrl: '',
          title: '',
          subtitle: '',
        }}
        image={<Hast node={mainImageFragment} config={hastConfig} />}
      />
    </MediaCardWrapperCss>
  );
};

const HeadingWithLinkAndArrow: React.FC<{
  headingComponentName: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  nodes: hast.HastNode[] | undefined;
  hastConfig: HastConfig;
  linkHref: string | null;
  isFeatured: boolean;
  isLatest: boolean;
  isSmall: boolean;
  linkArrowIconColor?: string;
}> = ({ headingComponentName, nodes, hastConfig, linkHref, isFeatured, isLatest, isSmall }) => {
  const headerSize = ((isFeatured || isLatest) && 'lg') || (isSmall && 'sm') || 'md';

  return (
    <PromotedContentHeading hTag={headingComponentName} size={headerSize}>
      {linkHref ? (
        <PromotedContentLink to={linkHref} rightIcon={ArrowRight} $size={headerSize}>
          <HastFragment nodes={nodes} config={hastConfig} />
        </PromotedContentLink>
      ) : (
        <HastFragment nodes={nodes} config={hastConfig} />
      )}
    </PromotedContentHeading>
  );
};

const Headers: React.FC<{
  hastFragment: hast.HastFragmentNode;
  hastConfig: HastConfig;
  linkHref: string | null;
  isFeatured: boolean;
  isLatest: boolean;
  isSmall?: boolean;
  linkArrowIconColor?: string;
  isMarketingArticle?: boolean;
}> = ({ hastFragment, hastConfig, linkHref, isFeatured, isLatest, isSmall = false, linkArrowIconColor }) => {
  return renderFragmentElements(hastFragment, hastConfig, (element, index) => {
    switch (element.tagName) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        return (
          <HeadingWithLinkAndArrow
            key={index}
            headingComponentName={element.tagName}
            nodes={element.children}
            hastConfig={hastConfig}
            linkHref={linkHref}
            isFeatured={isFeatured}
            isLatest={isLatest}
            isSmall={isSmall}
            linkArrowIconColor={linkArrowIconColor}
          />
        );
      default:
        return <Hast key={index} node={element} config={hastConfig} />;
    }
  });
};

const Notice: React.FC<{
  notice: StaticNotice;
  feed?: string;
  isLatest: boolean;
  displayImage: boolean;
  displayCategory: boolean;
  displayDescription?: boolean;
  displayPublishedAt: boolean;
  shouldFlex?: boolean;
  hasSmallTitle?: boolean;
  hasSeparator?: boolean;
  linkArrowIconColor?: string;
  fontSize?: string;
}> = ({
  notice,
  feed,
  isLatest,
  displayImage,
  displayCategory,
  displayDescription = true,
  displayPublishedAt,
  shouldFlex = true,
  hasSmallTitle,
  hasSeparator = false,
  linkArrowIconColor,
  fontSize = '1.125rem',
}) => {
  const {
    firstPublishedAt,
    contentCategory: { semanticKey: contentCategoryKey, name: contentCategoryName },
    content,
    contributions: { edges: contributionEdges },
    title,
  } = notice;
  const contributor = contributionEdges[0] && contributionEdges[0].node;
  const isFeatured = false; // TODO: Convert to prop?
  const isMarketingArticle = feed === 'forside-reklame';

  const expertContributor = isExpertComment(contentCategoryKey) && contributor && contributor.hasPortrait ? contributor : null;

  const linkHref = noticeLinkHref(notice);

  const allHeadersFragment = noticeHeadersSelector.select('allHeaders', content.hast);
  const hastConfig = new HastConfig([overrideIframeTitle('podcast'), overrideImg({ alt: title, loading: 'lazy' })]);
  return (
    <>
      <NoticeWrapperCss shouldFlex={shouldFlex} hasSeparator={hasSeparator} isMarketingArticle={isMarketingArticle}>
        {displayImage && (
          <MainImage
            hastConfig={hastConfig}
            linkHref={linkHref}
            isLatest={isLatest}
            expertContributor={expertContributor}
            allHeaders={allHeadersFragment}
            title={title}
            shouldFlex={shouldFlex}
            isMarketingArticle={isMarketingArticle}
          />
        )}
        <ContentCss spaceAround={isMarketingArticle} isMarketingArticle={isMarketingArticle}>
          {displayCategory && <TypeCss>{feed === 'forside-reklame' ? 'aktuelt' : contentCategoryName}</TypeCss>}
          <Headers
            hastFragment={mainImageSelector.complement(allHeadersFragment)}
            hastConfig={hastConfig}
            linkHref={linkHref}
            isFeatured={isFeatured}
            isLatest={isLatest}
            isSmall={hasSmallTitle}
            linkArrowIconColor={linkArrowIconColor}
            isMarketingArticle={isMarketingArticle}
          />
          {displayDescription && (
            <ContentCss fontSize={fontSize}>
              {displayPublishedAt && [
                <PublishedAtCss key={firstPublishedAt || ''}>{firstPublishedAt && formatDate(firstPublishedAt)}</PublishedAtCss>,
                <DividerDot key={`${firstPublishedAt}-dividerDot`} />,
              ]}
              <DescriptionCss isMarketingArticle={isMarketingArticle}>
                <noticeHeadersSelector.Complement fragment={content.hast} config={hastConfig} />
              </DescriptionCss>
            </ContentCss>
          )}
        </ContentCss>
      </NoticeWrapperCss>
    </>
  );
};

export default Notice;
