import gql from 'graphql-tag';

import * as GqlResult from './graphql/GqlResult';
import { useApolloQuery } from './hooks/useApolloQuery';
import { StaticNoticeEdge } from '../components/NoticeFeed';
import { concatGqlConnections, GqlConnection } from './graphql/GqlConnection';
import { FeedSemanticKey } from '../models/staticContent/Feed';

interface GqlVariables {
  feed: string;
  first: number;
  tagPaths: Array<Array<string>> | undefined;
  contentCategorySemanticKeys: Array<string> | undefined;
  cursor: string | undefined;
}

export interface GqlFeed {
  publishedNotices: GqlConnection<StaticNoticeEdge>;
}

export const FEED_QUERY = gql`
  query Feed($feed: String!, $first: Int!, $cursor: String, $tagPaths: [[String!]!], $contentCategorySemanticKeys: [String!]) {
    publishedNotices(
      feed: $feed
      first: $first
      cursor: $cursor
      tagPaths: $tagPaths
      contentCategorySemanticKeys: $contentCategorySemanticKeys
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          firstPublishedAt
          lastPublishedAt
          title
          contentCategory {
            semanticKey
            name
          }
          content {
            hast
          }
          contributions {
            edges {
              cursor
              role
              node {
                id
                slug
                names
                isFeatured
                hasPortrait
              }
            }
          }
          link {
            slug
          }
          customLink
        }
      }
    }
  }
`;

export default (
  feed: FeedSemanticKey,
  initialLoadCount: number,
  displayMoreInterval: number,
  tagPaths?: Array<Array<string>>,
  contentCategorySemanticKeys?: Array<string>
): {
  fetchFirstPage: GqlResult.Result<GqlConnection<StaticNoticeEdge>>;
  fetchNextPage: (cursor: string) => void;
} => {
  const [result] = useApolloQuery<GqlFeed, GqlVariables>(FEED_QUERY, {
    variables: {
      feed,
      first: initialLoadCount,
      tagPaths: tagPaths || undefined,
      contentCategorySemanticKeys: contentCategorySemanticKeys || undefined,
      cursor: undefined,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    fetchFirstPage: GqlResult.of(result).flatMapOk<GqlConnection<StaticNoticeEdge>>((data) => {
      if (!data.publishedNotices) {
        return GqlResult.unknownError();
      }
      return GqlResult.ok(data.publishedNotices);
    }),
    fetchNextPage: (cursor: string) => {
      result.fetchMore({
        query: FEED_QUERY,
        variables: {
          feed,
          first: displayMoreInterval,
          tagPaths: tagPaths || undefined,
          contentCategorySemanticKeys: contentCategorySemanticKeys || undefined,
          cursor,
        },
        updateQuery: (prev: GqlFeed, { fetchMoreResult }): GqlFeed => {
          if (!fetchMoreResult) return prev;

          return {
            publishedNotices: concatGqlConnections(prev.publishedNotices, fetchMoreResult.publishedNotices),
          };
        },
      });
    },
  };
};
