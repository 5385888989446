import styled, { css } from 'styled-components';
import { ColorTokenValues } from 'theme/config/types';

export interface ContainerProps {
  backgroundColor?: ColorTokenValues;
  textColor?: ColorTokenValues;
  overflow?: boolean;
}

export const Section = styled.section<ContainerProps>`
  background-color: ${(props) => props.backgroundColor && props.backgroundColor};
  color: ${(props) => (props.textColor ? props.textColor : props.theme.colorTokens.default.black)};

  ${(props) =>
    props.overflow &&
    css`
      overflow: auto;
    `}
`;

export const DivContainer = Section.withComponent('div');
export const ArticleContainer = Section.withComponent('article');
export const AsideContainer = Section.withComponent('aside');
export const FooterContainer = Section.withComponent('footer');

export default Section;
