import * as React from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { listWithoutStyle } from '../../styleMixins/listMixins';

const CollectionListCss = styled.ul`
  ${listWithoutStyle};
`;

export interface CollectionItemProps {
  padding?: 'none' | 'xs' | 'small' | 'large';
  hasSeparator?: boolean;
  isFullWidth?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

interface CollectionListProps {
  children?: React.ReactNode | React.ReactNode[];
}

const CollectionItemCss = styled.li<CollectionItemProps>`
  border-bottom: ${(props) => props.hasSeparator && `0.0625rem solid ${props.theme.colorTokens.brown[400]}`};

  ${(props) => {
    switch (props.padding) {
      case 'xs': {
        return css`
          padding: 0.5rem 0;
        `;
      }
      case 'small': {
        return css`
          padding: 1.25rem 0;
        `;
      }
      case 'large': {
        return css`
          padding: 2rem 0;

          ${createMediaQuery(
            'md',
            css`
              padding: 2rem 1.5rem;
            `
          )}
        `;
      }
    }
  }}
`;

export const CollectionItem: React.FC<CollectionItemProps> = ({ children, ...styleProps }) => {
  return (
    <CollectionItemCss id="notice" {...styleProps}>
      {children}
    </CollectionItemCss>
  );
};

export const CollectionList: React.FC<CollectionListProps> = ({ children }) => <CollectionListCss>{children}</CollectionListCss>;
