import * as React from 'react';
import { Link } from 'react-router-dom';
import * as hast from '@universitetsforlaget/hast';
import { HastConfig, DEFAULT_HAST_CONFIG } from 'util/hastConfig';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import Image from './Image';
import { Hast } from './Hast';
import Lock from './icons/Lock';

const MediaCardCss = styled.div`
  width: 100%;
  text-align: center;

  a {
    display: block;
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    text-decoration: none;
  }
`;

const ImageCss = styled.div`
  width: 100%;

  img {
    max-height: 30rem;
    max-width: 100%;
  }
`;

const TitleCss = styled.h3`
  margin-bottom: 10px;
  font-size: 1.25rem;
  word-break: break-word;

  ${createMediaQuery(
    'sm',
    css`
      margin-bottom: 20px;
    `
  )}
`;

const SubtitleCss = styled.p`
  margin-top: 10px;
  font-style: italic;
`;

const MediaCard: React.FC<{
  media: {
    ariaLabel?: string;
    path: string;
    imgUrl: string;
    title: string | hast.HastElementNode;
    subtitle?: string | hast.HastElementNode;
  };

  // Optional custom image component
  image?: React.ReactElement;
  hastConfig?: HastConfig;
  accessible?: boolean;
}> = ({ media, image, hastConfig = DEFAULT_HAST_CONFIG, accessible = true }) => (
  <MediaCardCss>
    <Link aria-label={media.ariaLabel} to={media.path}>
      <ImageCss>{image || <Image src={media.imgUrl} alt={media.title.toString()} />}</ImageCss>
      <div>
        <TitleCss>
          {!accessible && <Lock title="Dette innholdet er ikke i ditt abonnement" mr2 />}
          {typeof media.title === 'object' ? <Hast node={media.title} config={hastConfig} /> : media.title}
        </TitleCss>

        {!!media.subtitle && (
          <SubtitleCss>
            {typeof media.subtitle === 'object' ? <Hast node={media.subtitle} config={hastConfig} /> : media.subtitle}
          </SubtitleCss>
        )}
      </div>
    </Link>
  </MediaCardCss>
);

export default MediaCard;
